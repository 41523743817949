import { useState } from "react";
import { usePhoneInput } from "react-international-phone";

export const PhoneFormatting =({selectRegionCode,phoneNumber}) =>{
    const [phoneNo,setPhoneNo] = useState()
    const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
        defaultCountry: selectRegionCode
          ? selectRegionCode.toLowerCase()
          : "us",
        value: phoneNumber,
        disableCountryGuess: true,
        disableDialCodeAndPrefix: true,
        onChange:(data) => {
            // eslint-disable-next-line no-unused-expressions
            setPhoneNo(data.phone)
        }
        
      });
     return (<>{phoneNo}</>)
    }