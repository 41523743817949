import { FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { usePhoneInput } from "react-international-phone";

export const PhoneFormattingInput = ({
  id,
  label,
  setCustomerPhone,
  setSelectPhone,
  isRegionCode,
  resetSearch
}) => {
  const [inputPhone, setInputPhone] = useState();
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: isRegionCode ? isRegionCode.toLowerCase() : "us",
      value: inputPhone,
      disableCountryGuess: true,
      disableDialCodeAndPrefix: true,
      onChange: (data) => {
        setInputPhone(data.phone);
      },
    });
    
  useEffect(() => {
    const phoneNo = inputPhone?.replace(/\D/g, "");
    if(resetSearch === 1) {
      setInputPhone('')
    }
    if (setCustomerPhone) {
      setCustomerPhone(phoneNo);
    } else {
      setSelectPhone(phoneNo);
    }

  }, [inputPhone,resetSearch]);

  return (
    <>
      <FormControl fullWidth>
        <TextField
          type="tel"
          size="small"
          id={id}
          label={label}
          variant="outlined"
          placeholder="(XXX)-XXX-XXXX"
          value={inputPhone ? inputPhone : ""}
          onChange={(event) => {
            setInputPhone(event.target.value);
            handlePhoneValueChange(event);
          }}
        />
      </FormControl>
    </>
  );
};
